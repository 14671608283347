@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

/* BROWSER RESETS */
* {
  box-sizing: border-box;
}
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
}
img {
  display: block;
  width: 100%;
}
h1, h2, h3, h4 {
  padding: 0;
  margin: 0;
  line-height: 1.2;
  color: #333;
}
h1, h2, h3, h4 {
  font-weight: 300;
}
h2 {
  font-size: 40px;
  text-transform: capitalize;
}
h3 {
  font-size: 32px;
  text-transform: capitalize;
}
h4 {
  font-size: 28px;
}
p {
  font-size: 22px;
  font-weight: 100;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  color: #333;
}

.m-t-10 {
  margin-top: 10px;
}

.small-text {
  font-size: 18px !important;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.fade-in {
  animation: fadeIn .35s ease 2s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* CONTAINER */
.container-500 {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 150px 0;
}
input.welcome-form-control {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #999;
  margin-bottom: 10px;
}
.input-wrapper {
  margin-bottom: 20px;
}
textarea.welcome-form-control {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  display: block;
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #999;
  margin-bottom: 10px;
}
.container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}
.container-narrow {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}
.container-ultra-narrow {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
.max-width-400 {
  display: block;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.max-width-600 {
  max-width: 600px;
  width: 100%;
}
.max-width-800 {
  max-width: 800px;
  width: 100%;
}
.max-width-1000 {
  max-width: 1000px;
  width: 100%;
}

/* ALIGN TEXT */
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}

/* POSITION */
.center {
  margin: 0 auto;
}

/* COLOR */
.dark-blue-bg {
  background: #1d242e;
}
.medium-blue-bg {
  background: #0071bc;
}
.light-blue-bg {
  background: #94afb8;
}
.light-bg {
  background: #e9e9e9;
}
.alert-bg {
  background: #e63946;
  color: #FFF !important;
}
.alert-bg a {
  color: #FFF !important;
}
.white {
  color: #e6e9f2;
}
.black {
  color: #000 !important;
}
.notice {
  font-size: 18px;
  line-height: 1.4;
}
.tight {
  line-height: 1.2;
}

/* BUTTONS */
.btn {
  display: block;
  width: 200px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}
.btn-large {
  width: 300px !important;
}
.btn-light-blue {
  background: #AAB8BC;
  color: #333;
  transition: all .3s;
}
.btn-medium-blue {
  background: #647789;
  color: #FFF;
}
.btn-dark-blue {
  background: #1d242e;
  border: 1px solid #1d242e;
  color: #FFF;
  transition: all .3s;
}
.btn-dark-blue:hover {
  background: #AAB8BC;
  border: 1px solid #1d242e;
  color: #1d242e;
}
.btn-light-blue:hover {
  background: #FFFFFF;
}

/* FLEX STYLES */
.flex {
  display: flex;
  flex-wrap: wrap;
}
.flex-nowrap {
  display: flex;
  gap: 10px;
}
.flex-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-gap {
  justify-content: space-between;
}
.flex-start {
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.width-25 {
  width: 25%;
}
.width-30 {
  width: 32%;
}
.width-33 {
  width: 33%;
}
.width-50 {
  width: 50%;
}
.width-66 {
  width: 66%;
}
.width-75 {
  width: 75%;
}
/* PADDING & MARGIN */

.margin-top-5 {
  margin-top: 5px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.padding-10 {
  padding: 10px;
}
.padding-20 {
  padding: 20px;
}
.padding-0-50 {
  padding: 20px;
}
.padding-side-20 {
  padding: 0 20px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-75 {
  padding-top: 75px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-50-20 {
  padding: 50px 20px;
}
.padding-100-20 {
  padding: 100px 20px;
}
.padding-25-20-100 {
  padding: 25px 20px 100px 20px;
}
.border-white {
  border: 1px solid #F1FAEE;
}
.border-top-dark {
  border-top: 1px solid #1D3557;
}
.border-top-medium {
  border-top: 1px solid #647789;
}
.border-top-light {
  border-top: 1px solid #94afb8;
}

.border-left {
  border-left: 3px solid #333;
}

/* MAIN NAV */
#main-nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}
#header-logo {
  width: 250px;
  margin: 0 auto;
  padding: 10px;
}
#nav-links {
  display: flex;
  justify-content: center;
}
#nav-links li {
  list-style-type: none;
}
.has-dropdown {
  position: relative;
}
.has-dropdown:hover .dropdown {
  display: block;
}
.dropdown {
  position: absolute;
  padding: 0;
  margin: 0;
  display: none;
}
.dropdown li {
  width: 200px;
  border-bottom: 1px solid #283240;
}
.dropdown li:last-child {
  border-bottom: none;
}
.dropdown li a {
  margin: 0;
  padding: 10px 20px !important;
  margin: 0 !important;
  background: #1D3557;
  background: #1d242e;
  transition: .2s all;
}
.dropdown li a:hover {
  background: #1a304e;
  background: #283240;
}
#nav-links a {
  font-size: 16px;
  display: block;
  letter-spacing: 1px;
  color: #FFF;
  text-decoration: none;
  padding: 5px 0 5px 0;
  margin: 0 20px;
}
a.nav-link {
  border-bottom: 2px solid rgba(255,255,255,0);
  transition: .2s all;
}
a.nav-link:hover {
  border-bottom: 2px solid rgba(255,255,255,1);
}

/* HOME HERO */
#hero {
  width: 100%;
  background-image: url(./images/home-hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
}

#hero2 {
  background-image: url(./images/home-hero2-bg.jpg);
}
#hero-message {
  position: absolute;
  max-width: 650px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  background: rgba(255,255,255,.65);
  padding: 20px;
}
#hero-message h1 {
  font-size: 72px;
  font-weight: 300;
  line-height: 1;
  text-align: right;
}
#hero-message p {
  font-size: 24px;
  font-weight: 400;
  padding: 10px 0 0 0;
  text-align: right;
}

/* PAGE HERO */
.page-message {
  padding: 140px 20px 100px 20px;
}
.page-message p {
  color: #000;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}
.page-hero {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  height: 60vh;
}
.page-hero-message {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(255,255,255,.65);
  padding: 20px 20px 20px 100px;
}

#jubilee-hero {
  position: relative;
  background-image: url(./images/jubilee-hero-bg.jpg);
}
.h1 {
  font-size: 48px;
  color: #FFF;
  text-shadow: 0px 2px 4px rgba(0,0,0,1);
}
.h2 {
  font-size: 30px;
  color: #FFF;
  text-shadow: 0px 2px 4px rgba(0,0,0,1);
}

.page-hero-announcement {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 700;
  padding: 20px;
}

#page-hero {
  background-image: url(./images/page-hero-bg.jpg);
}
#believe-hero {
  background-image: url(./images/believe-hero-bg.jpg);
}
#pastor-hero {
  background-image: url(./images/pastor-hero-bg.jpg);
}
#messages-hero {
  background-image: url(./images/messages-hero-bg.jpg);
}
#sunday-hero {
  background-image: url(./images/sunday-hero-bg.jpg);
}
#live-hero {
  background-image: url(./images/live-hero-bg.jpg);
}
#ministries-hero {
  background-image: url(./images/ministries-hero-bg.jpg);
}
#reading-hero {
  background-image: url(./images/reading-hero-bg.jpg);
}
#missions-hero {
  background-image: url(./images/missions-hero-bg.jpg);
}
#contact-hero {
  background-image: url(./images/contact-hero-bg.jpg);
}
#welcome-hero {
  background-image: url(./images/welcome-hero-bg.jpg);
}

/* FOOTER */
footer a {
  text-decoration: none;
  color: #FFF;
}
footer a:hover {
  text-decoration: underline;
}
.footer-menu {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 20px auto;
}
.menu-group {
  width: 32%;
}
.menu-group h3 {
  font-size: 24px;
  color: #FFF;
  margin-bottom: 10px;
}
.menu-group li {
  list-style-type: none;
  line-height: 1.5;
}
.menu-group a {
  font-size: 18px;
  color: #FFF;
}
#social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 250px;
  width: 100%;
  margin: 50px auto 20px auto;
}
#social a {
  display: block;
  width: 15%;
}
#hamburger {
  display: none;
}
footer a, footer p {
  font-size: 18px;
}

/* BELIEF PAGE */
.belief {
  padding: 30px 0;
  line-height: 1.5;
  border-bottom: 1px solid #AAB8BC;
}
.belief:last-child {
  border-bottom: none;
}
.belief h3 {
  padding-bottom: 10px;
}

/* MESSAGES PAGE */
.message-block {
  position: relative;
  background: #FFF;
  margin-bottom: 30px;
  box-shadow: 0px 10px 20px rgba(0,0,0,.2);
}
.message-info {
  padding: 10px 15px 45px 15px;
  position: relative;
  height: 110px;
}
.single-message-info {
  padding: 135px 15px 45px 15px;
}
.jubilee-info {
  padding: 10px 15px 45px 15px;
  position: relative;
  height: 85px;
}
.message-title {
  display: -webkit-box;   
  -webkit-line-clamp: 2;   
  -webkit-box-orient: vertical;     
  overflow: hidden; 
  font-size: 24px;
  color: #000;
  line-height: 1.1;
  height: 70px;
}
.missionary-block {
  position: relative;
  background: #FFF;
  margin-bottom: 20px;
  border: 4px solid #FFF;
  box-shadow: 0px 10px 20px rgba(0,0,0,.2);
}
.missionary-title {   
  font-size: 24px;
  color: #000;
  line-height: 1.1;
}
.message-servicedate {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 15px;
}
.message-service {
  font-size: 14px;
  color: #000;
  text-align: left;
}
.message-date {
  font-size: 14px;
  color: #000;
  float: right;
}
.preacher {
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
.message-links {
  justify-content: center;
  align-items: center;
  row-gap: 20px;
}
.message-link {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  display: block;
  color: #000;
  text-decoration: none;
  text-align: center;
  padding: 15px;
  border-top: 1px solid #e6e9f2;
}
.message-link:hover {
  text-decoration: underline;
}
/* .message-link::after {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  transform: translate(5px, 8px) rotate(45deg);
} */
.series-link {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  display: block;
  color: #000;
  text-decoration: none;
  text-align: center;
  padding-bottom: 15px;
}
.share-btn {
  display: block;
  width: 100px;
  margin: 10px auto;
  background-color: #4267b2;
  border: 0px;
  cursor: pointer;
}
.share-btn img {
  width: 100%;
  margin: 0 auto;
}

.share-btn2 {
  height: 40px;
  position: absolute;
  bottom: 0;
  display: block;
  flex: 1;
  width: 80%;
  background-color: #4267b2;
}
.share-btn2 img {
  max-width: 125px;
  width: 100%;
  margin: 0 auto;
}
.jub-year {
  width: 20%;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #333;
  color: #fff;
}

.message-search {
  position: relative;
  margin-bottom: 50px;
}
.jubilee-search {
  position: relative;
  margin-bottom: 20px;
}

.search-form-absolute {
  position: absolute;
  overflow: hidden;
}
.search-icon {
  position: absolute;
  z-index: 1;
  font-size: 30px;
  top: 12px;
  left: 10px;
  color: #999;
}
input.form-control {
  font-family: 'Open Sans', sans-serif;
  position: relative;
  font-size: 18px;
  font-weight: 300;
  padding: 15px 10px 15px 50px;
  border: none;
  background: #fff;
  width: 100%;
}
button.form-control-absolute {
  position: absolute;
  font-size: 18px;
  font-weight: 300;
  z-index: 1;
  right: 0;
  border: none;
  background: #1D3557;
  color: #FFF;
  padding: 20px;
  cursor: pointer;
}

.single-title {
  font-size: 24px;
}
.single-date {
  font-size: 14px;
  font-weight: 400;
}
.single-speaker {
  font-size: 18px;
}

/* AUDIO PLAYER */

#audio-player {
  width: 100%;
  margin-top: 20px;
  outline: none;
}
audio::-webkit-media-controls-panel {
  background-color: #FFF;
}

.audio-container {
  display: flex;
  align-items: center;
  background-color: #1d3557;
  background-image: url(./images/audio-player-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.audio-player {
  max-width: 600px;
  max-height: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
.audio-player > * {
  color: #FFF;
  text-align: center;
}
.audio-player p {
  font-size: 14px;
  margin: 20px 0;
}
.audio-player h3 {
  font-size: 24px;
}
.audio-player audio {
  width: 100%;
  margin-top: 20px;
}
#play-btn {
  width: 60px;
  display: none;
  cursor: pointer;
}
#pause-btn {
  width: 60px;
  display: none;
  cursor: pointer;
}
#audio-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: space-around;
  align-items: center;
}
#track-back, #track-forward {
  width: 45px !important;
  cursor: pointer;
}

.selection-toggle {
  max-width: 300px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selection-toggle button {
  cursor: pointer;
}
.active {
  display: block;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 1px;
  width: 49%;
  border: none;
  background: #4267b2;
  color: #FFF;
  border-radius: 20px;
  outline: none;
}
.inactive {
  display: block;
  padding: 8px;
  font-size: 18px;
  letter-spacing: 1px;
  width: 49%;
  border: none;
  color: #4267b2;
  border-radius: 20px;
  border: 2px solid #4267b2;
  outline: none;
}

.video-list:nth-child(even) {
  background-color:rgba(255,255,255,.65);
}

.video-title {
  font-size: 24px;
}
.video-date {
  font-size: 20px;
}

.video-list-title {
  font-size: 20px;
  line-height: 1.1;
}
.video-list-date {
  font-size: 16px;
}
.video-list-btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

.image-wrap {
  overflow: hidden;
}

.embed-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.embed-video-container iframe {
  border: 0px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-section {
  position: sticky;
  top: 120px;
}

.map-view {
  display: block;
  height: 360px;
  position: relative;
}

.map-view-mobile {
  display: none;
}

.map-view a {
  display: block;
  width: 100%;
  height: 100%;
}
.map-view iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

#modal-bg {
  position: fixed;
  inset: 0;
  z-index: 99;
  background-color: rgba(0,0,0,.65);
}
#modal-container {
  position: absolute;
  width: 85%;
  max-width: 600px;
  padding: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(hsl(204, 100%, 90%), #FFF);
  border: 5px solid #FFF;
  box-shadow: 0 3px 9px rgba(0,0,0,1);
}
#close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  cursor: pointer;
}
.hide-modal {
  display: none;
}
#line {
  height: 1px;
  background-color: #000;
  margin: 20px 0;
}

@keyframes parallax-effect {
  from {
    opacity: 0;
    transform: translateY(100px)
  }
  to {
    opacity: 1;
    transform: translateY(0px)
  }
}
.parallax {
  animation: parallax-effect linear both;
  animation-timeline: view();
  animation-range-start: cover 0;
  animation-range-end: entry 50dvh;
}

@media screen and (min-width:1101px){
  .width-30-lg {
    width: 32%;
  }
}

@media screen and (max-width:1100px) and (min-width:641px){
  .width-50-md {
      width: 48%;
  }
}

@media screen and (max-width:770px), (orientation: portrait){
  .page-hero-announcement {
    background-color: rgba(0,0,0,.25);
  }
  .h1 {
    font-size: 30px;
  }
  .h2 {
    font-size: 20px;
  }
  #hero, .page-hero {
      background-attachment: scroll;
  }
  #hero {
    background-position: top right;
}
  .page-hero {
    background-position: center;
    background-size: cover;
}
  .page-message {
    padding: 120px 20px 100px 20px;
  }
  header {
    overflow: hidden;
  }
  .mobile-touch {
    position: fixed;
    right: 10px;
    top: 15px;
    color: #FFF;
    z-index: 9;
    font-size: 40px;
  }
  #header-logo {
      width: 250px;
      margin: 0;
      padding: 10px;
  }
  p {
      font-size: 18px;
  }
  #open {
      display: flex;
      align-items: center;
      position: fixed;
      top: 25px;
      right: 10px;
      width: 30px;
      height: 25px;
      z-index: 9;
      cursor: pointer;
      transition: .3s transform;
  }
  .open {
      width: 100%;
      height: 4px;
      background: #FFF;
  }
  .open::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 4px;
      background: #FFF;
  }
  .open::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      background: #FFF;
  }
  
  #close {
      display: flex;
      align-items: center;
      position: fixed;
      top: 25px;
      right: 10px;
      width: 30px;
      height: 25px;
      z-index: 9;
      cursor: pointer;
      transition: .3s transform;
  }
  .close {
      width: 100%;
      height: 4px;
      background: none;
  }
  .close::before {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      height: 4px;
      background: #000;
      transform: translateY(-50%) rotate(45deg);
  }
  .close::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
      height: 4px;
      background: #000;
      transform: translateY(-50%) rotate(-45deg);
  }

  .mobile-touch {
    transition: all .3s;
  }
  
  .slide-hamburger {
      transform: translateX(60px);
  }
  
  #nav-links {
      display: block;
      position: fixed;
      left: -100%;
      top: 0;
      padding: 30px;
      background: #e6e9f2;
      width: 100%;
      height: 100vh;
      transition: .3s left;
      z-index: 999;
  }
  .slide-nav {
      left: 0% !important;
  }
  .dropdown {
    position: relative;
    display: block;
}
  .dropdown li {
    background: #f1f2f5;
    border-bottom-color: #dbe0f1;
    width: auto;
    padding-left: 15px;
}
#nav-links a {
  font-size: 14px;
    padding: 5px 0px !important;
    margin: 0 !important;
    background: none;
    color: #333;
    font-size: 20px;
    font-weight: 400;
}
}



@media screen and (max-width:640px){
  #hero-message {
      width: 90%;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
  }
  #hero-message h1 {
      font-size: 42px;
      font-weight: 300;
      text-align: center;
  }
  #hero-message p {
      font-size: 18px;
      padding: 20px 0 0 0;
      text-align: center;
  }
  
  
  footer p, footer a {
      font-size: 14px;
  }
  .menu-group h3 {
      font-size: 18px;
  }
  .menu-group a {
      font-size: 14px;
  }
  h2 {
      font-size: 30px;
  }
  .flex {
      display: block;
  }
  .width-25, .width-30, .width-33, .width-50, .width-66, .width-75 {
      width: 100%;
  }
  .page-hero-message {
      padding: 10px 15px;
  }
  #pastor-hero {
      background-image: url(./images/pastor-hero-bg-m.jpg);
  }
  #messages-hero {
    background-image: url(./images/messages-hero-bg-m.jpg);
  }
  #jubilee-hero {
    background-image: url(./images/jubilee-hero-bg-m.jpg);
  }
  #reading-hero {
    background-image: url(./images/reading-hero-bg-m.jpg);
  }
  #contact-hero {
    background-image: url(./images/contact-hero-bg-m.jpg);
  }
  #welcome-hero {
    background-image: url(./images/welcome-hero-bg-m.jpg);
    height: 275px;
    background-position-y: top;
  }
  
  .message-block {
    margin-bottom: 20px;
  }
  .message-search {
    margin-bottom: 20px;
  }
  .padding-20-s {
    padding: 20px !important;
  }
  .margin-bottom-20-s {
    margin-bottom: 20px;
  }
  .padding-bottom-20-s {
    padding-bottom: 20px;
  }
  .padding-side-0-s {
    padding: 0;
  }
  .padding-side-5-s {
    padding: 0 5px;
  }
  .padding-top-50-s {
    padding-top: 50px;
  }
  .padding-50-0 {
    padding: 50px 0;
  }
  

  .video-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .video-list {
    background-color:rgba(255,255,255,.65);
  }

  .overflow-scroll {
    overflow: scroll;
  }
  .slide-container {
    width: 800%;
    display: flex;
  }

  .hidden-s {
    display: none !important;
  }

  .map-view {
    display: none;
  }
  .map-view-mobile {
    display: block;
    height: 360px;
    position: relative;
  }
  .map-view-mobile a {
    display: block;
    width: 100%;
    height: 100%;
  }
  .map-view-mobile iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
  }
}